import "core-js/modules/es.date.to-string"; /*
                                             * @Description:
                                             * @Version: 1.0
                                             * @Autor: silencc
                                             * @Date: 2020-11-13 14:58:21
                                             * @LastEditors: Please set LastEditors
                                             * @LastEditTime: 2021-12-15 10:17:47
                                             */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { auditStatus, formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '医生姓名',
    kname: 'name',
    width: 100 },

  {
    label: '手机号',
    kname: 'phone' },

  // {
  //   label: '医院名称',
  //   kname: 'hospitalName'
  // },
  {
    label: '推荐人姓名',
    kname: 'recommendName' },

  {
    label: '推荐号码',
    kname: 'recommendCode' },

  {
    label: '申请时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status',
    /*filterFn(v) {
                       return auditStatus[v].label
                     },*/
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus },

      props: {} } },



  {
    label: '操作',
    kname: 'btns',
    width: 260 }];};




export var searchOption = function searchOption(ctx) {return [
  {
    kname: 'doctorName',
    label: '医生姓名：',
    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入医生姓名',
        clearable: true } } },



  // {
  //   kname: 'codeArea',
  //   label: '地区：',
  //   component: {
  //   }
  // },
  // {
  //   kname: 'hospitalName',
  //   label: '医院：',
  //   component: {
  //     name: 'el-input',
  //     props: {
  //       placeholder: '请输入医院名称',
  //       clearable: true
  //     }
  //   }
  // },
  // {
  //   kname: 'departmentId',
  //   label: '科室：',
  //   component: {
  //     name: 'selector'
  //   }
  // },
  // recommendName
  {
    kname: 'recommendName',
    label: '推荐人姓名',
    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入推荐人姓名',
        clearable: true } } },



  {
    kname: 'doctorPhone',
    label: '医生手机号：',
    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入医生手机号',
        clearable: true } } },



  {
    kname: 'recommendPhone',
    label: '推荐手机号：',
    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入推荐手机号',
        clearable: true } } },



  {
    kname: 'startTime',
    label: '开始时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 00:00:00',
        pickerOptions: {
          disabledDate: function disabledDate(v) {
            if (v.valueOf() > new Date(new Date().toLocaleDateString()).valueOf()) {
              return true;
            }
            return false;
          } } } } },




  {
    kname: 'endTime',
    label: '结束时间：',
    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        valueFormat: 'yyyy-MM-dd 23:59:59',
        pickerOptions: {
          disabledDate: ctx.showEndTime } } } }];};